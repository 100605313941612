import { useLocation, useNavigate } from "react-router-dom"
import { Microsite, MicrositeService } from "../../../domain/entities/Microsite"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { MicrositesViewModel } from "./MicrositesViewModel"
import { createRef, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import MainForm, { MainFormRef } from "../../components/Form/MainForm"
import { Button, Col, Row } from "antd"
import MicrositeConfigurationFormSection from "./components/MicrositeConfigurationFormSection"
import MicrositeInformationFormSection from "./components/MicrositeInformationFormSection"
import MicrositeVisualsFormSection from "./components/MicrositeVisualsFormSection"
import useUpdateEffect from "../../hooks/useUpdateEffect"
import { useTranslation } from "react-i18next"
import Switch from "../../components/Switch/Switch"
import { MicrositeOption } from "./const/MicrositeOption"
import { Card } from "../../styledComponents/CustomCard/Card"
import MicrositeServicesFormSection from "./components/MicrositeServicesFormSection"
import MicrositeServicesModal from "./components/MicrositeServicesModal"
import BannersFormView from "../../components/BannersFormView/BannersFormView"
import { BannerLanguages } from "./const/BannerLanguages"
import MicrositeObjectiveFormSection from "./components/MicrositeObjectiveFormSection"
import MicrositeLandingReviewsFormSections from "./components/MicrositeLandingReviewsFormSections"
import MicrositeLandingReviewModal from "./components/MicrositeLandingReviewModal"
import MicrositeTipsFormSection from "./components/MicrositeTipsFormSection"
import MicrositeTipModal from "./components/MicrositeTipModal"

export type EditableService = MicrositeService & { editted?: boolean }

const MicrositesFormView = () => {
	const { t } = useTranslation("microsites", { keyPrefix: "form" })
	const navigate = useNavigate()
	const locationState: { microsite: Microsite; option: MicrositeOption } = useLocation().state
	const formRef = createRef<MainFormRef>()
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	const [modalVisible, setSessionModalVisible] = useState(false)
	const [reviewModalVisible, setReviewModalVisible] = useState(false)
	const [tipModalVisible, setTipModalVisible] = useState(false)

	useEffect(() => {
		viewModel.setSelectedMicrosite(locationState.microsite)
		viewModel.setEditMode(locationState?.microsite?.id !== undefined)
		viewModel.setInitialFormData(locationState?.microsite)
		viewModel.setFormData({
			...locationState?.microsite,
			id: locationState.option !== MicrositeOption.DUPLICATE ? locationState?.microsite.id : undefined
		})
		viewModel.setOriginalFormData(locationState?.microsite)
		locationState?.microsite?.id && viewModel.fetchMicrositeDetail(locationState?.microsite?.id)
		viewModel.setError("", undefined)
		viewModel.fetchBanners()
	}, [locationState?.microsite])

	useUpdateEffect(() => {
		const selectedMicrosite = toJS(viewModel.selectedMicrosite)
		viewModel.setFormData({
			...viewModel.formData,
			...selectedMicrosite,
			id: locationState.option !== MicrositeOption.DUPLICATE ? selectedMicrosite.id : undefined
		})
		viewModel.setOriginalFormData({ ...viewModel.formData, ...selectedMicrosite })
		viewModel.setEditMode(selectedMicrosite.id !== undefined)
	}, [viewModel.selectedMicrosite])
	const formData = toJS(viewModel.formData)
	const originalFormData = useMemo(() => toJS(viewModel.originalFormData), [viewModel.originalFormData])
	const formModified = JSON.stringify(formData) !== JSON.stringify(originalFormData)
	// const resetForm = () => {
	//     viewModel.setFormData(originalFormData);
	//     const initialFormNameFields = originalFormData.detail?.reduce((acc: any, curr) => {
	//         const key = curr?.key || curr?.id;
	//         acc['name' + key] = curr.name;
	//         acc['number' + key] = curr.number;
	//         acc['link' + key] = curr.link;
	//         acc['image' + key] = curr.image;
	//         return acc;
	//     }, {});
	//     formRef.current?.setFormValues({
	//         ...formData,
	//         ...initialFormNameFields
	//     });
	// }

	const onSubmitForm = async () => {
		await formRef.current?.customValidateFields(() => {
			if (!formData.currency) {
				throw { message: t("validations.currency") }
			}
			if (!formData.micrositeType) {
				throw { message: t("validations.partnerType") }
			}
			if (Number(formData.feePercentage) < 0 || Number(formData.feePercentage) > 100) {
				throw { message: t("validations.feePercentage") }
			}
			// if (formData.detail?.some(detail => !detail.clusterId)) {
			// 	viewModel.setError(t("validations.clusterDetail"), "detail")
			// 	throw { message: t("validations.clusterDetail") }
			// }
			if (!formData.settings?.businessType) {
				throw { message: t("validations.businessType") }
			}
		})
		if (viewModel.hasIncompleteBanners()) {
			throw { message: t("validations.incompleteBanner") }
		}
		await Promise.all([
			viewModel.sendFormData(),
			viewModel.saveBanners(locationState.option === MicrositeOption.DUPLICATE)
		])
		navigate(".", {
			replace: true,
			state: {
				...locationState,
				microsite: toJS(viewModel.formData),
				option: MicrositeOption.EDIT
			}
		})
	}

	return (
		<div>
			<MainForm onSubmitForm={onSubmitForm} initialValues={formData} formModified={formModified} ref={formRef}>
				<Row gutter={[24, 0]}>
					<Col xl={24} md={24} xs={24}>
						<Card
							bordered={false}
							className="criclebox tablespace mb-24"
							title={
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<h6 className="font-semibold m-0">{t("micrositeInfo.cardTitle")}</h6>
									{viewModel.editMode ? (
										<Switch
											title={t("status.active").toString()}
											checked={formData.status}
											loading={viewModel.isLoading || viewModel.isLoadingDetail}
											containerStyles={{ marginBottom: 0 }}
											titleStyles={{ fontSize: "1rem" }}
											onChange={value => viewModel.setFormData({ ...formData, status: value })}
										/>
									) : null}
								</div>
							}
							bodyStyle={{ padding: "24px" }}
						>
							<MicrositeInformationFormSection
								onChange={value => {
									viewModel.setFormData({ ...formData, ...value })
								}}
							/>
						</Card>
						<Row gutter={[24, 0]}>
							<Col md={12} xl={18} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace"
									title={<h6 className="font-semibold m-0">{t("visualsInfo.cardTitle")}</h6>}
									bodyStyle={{ padding: "24px", paddingLeft: "2.5rem" }}
								>
									<MicrositeVisualsFormSection
										data={formData}
										onChange={value => viewModel.setFormData({ ...formData, ...value })}
									/>
								</Card>
								<div className="my-5">
									<MicrositeObjectiveFormSection
										data={formData.objective}
										onChange={objective =>
											viewModel.setFormData({
												...formData,
												objective: objective ? { ...formData.objective, ...objective } : null
											})
										}
									/>
								</div>
							</Col>
							<Col md={12} xl={6} xs={24}>
								<Card
									loading={viewModel.isLoadingDetail}
									bordered={false}
									className="criclebox tablespace"
									title={
										<div>
											<h6 className="font-semibold m-0">{t("configInfo.cardTitle")}</h6>
										</div>
									}
									style={{ height: "95%" }}
									bodyStyle={{ padding: "24px", paddingBottom: "48px" }}
								>
									<MicrositeConfigurationFormSection
										data={formData}
										onChange={value => viewModel.setFormData({ ...formData, ...value })}
									/>
								</Card>
							</Col>
						</Row>
						<Row gutter={[24, 0]} style={{ marginBottom: "24px", marginTop: "8px" }}>
							<Col xl={24} md={24} xs={24}>
								{viewModel.selectedMicrosite?.slug && (
									<BannersFormView
										banners={viewModel.banners}
										title={t("banners.title")}
										language={BannerLanguages[formData.lang?.toLowerCase() ?? "es-es"]}
										microsite={viewModel.selectedMicrosite.slug}
										onBannerChange={banners => viewModel.setBanners(banners)}
										notCollapse={true}
										isLoading={viewModel.isLoading}
									/>
								)}
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={24} md={24} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div style={{ display: "flex", alignItems: "center" }}>
											<h6 className="font-semibold m-0">{t("servicesInfo.cardTitle")}</h6>
											<Button
												onClick={() => setSessionModalVisible(true)}
												type="primary"
												style={{
													marginLeft: "1.5vw",
													height: "34px",
													display: "flex",
													alignItems: "center"
												}}
											>
												{t("servicesInfo.newService")}
											</Button>
										</div>
									}
								>
									<MicrositeServicesFormSection
										onChange={(id, value) => {
											const servicesModified = formData.services?.map(service =>
												Number(service.id) === id
													? {
															...service,
															weight: value.weight,
															highlighted: value.highlighted || service.highlighted,
															updated: true
													  }
													: service
											)
											viewModel.setFormData({
												...formData,
												services: servicesModified
											})
										}}
									/>
								</Card>
							</Col>
						</Row>

						<Row gutter={[24, 0]}>
							<Col xl={24} md={24} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div style={{ display: "flex", alignItems: "center" }}>
											<h6 className="font-semibold m-0">{t("landingReviewsInfo.title")}</h6>
											<Button
												onClick={() => setReviewModalVisible(true)}
												type="primary"
												style={{
													marginLeft: "1.5vw",
													height: "34px",
													display: "flex",
													alignItems: "center"
												}}
											>
												{t("landingReviewsInfo.add")}
											</Button>
										</div>
									}
								>
									<MicrositeLandingReviewsFormSections
										onDelete={id =>
											viewModel.setFormData({
												...formData,
												landingReviews: formData.landingReviews?.filter(r => r.id !== id)
											})
										}
										onChange={(id, value) => {
											const modifiedReviews = formData.landingReviews?.map(review =>
												Number(review.id) === id
													? {
															...review,
															weight: Number(value.weight)
													  }
													: review
											)
											viewModel.setFormData({
												...formData,
												landingReviews: modifiedReviews
											})
										}}
									/>
								</Card>
							</Col>
						</Row>
						<Row gutter={[24, 0]}>
							<Col xl={24} md={24} xs={24}>
								<Card
									bordered={false}
									className="criclebox tablespace mb-24"
									title={
										<div style={{ display: "flex", alignItems: "center" }}>
											<h6 className="font-semibold m-0">{t("tipsInfo.title")}</h6>
											<Button
												onClick={() => setTipModalVisible(true)}
												type="primary"
												style={{
													marginLeft: "1.5vw",
													height: "34px",
													display: "flex",
													alignItems: "center"
												}}
											>
												{t("tipsInfo.add")}
											</Button>
										</div>
									}
								>
									<MicrositeTipsFormSection
										onDelete={id =>
											viewModel.setFormData({
												...formData,
												tips: formData.tips?.filter(t => t.tipId !== id)
											})
										}
										onChange={(id, value) => {
											const modifiedTips = formData.tips?.map(t =>
												Number(t.tipId) === id
													? {
															...t,
															position: Number(value.position)
													  }
													: t
											)
											viewModel.setFormData({
												...formData,
												tips: modifiedTips
											})
										}}
									/>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
			</MainForm>

			<MicrositeServicesModal
				visible={modalVisible}
				onCancel={() => setSessionModalVisible(false)}
				onConfirm={service => {
					if (!service.id) return
					const newService = {
						...service,
						id: service.id
					}
					viewModel.formData.services?.push({ ...newService, seller: { name: service.sellerName } })
					setSessionModalVisible(false)
				}}
			/>

			<MicrositeLandingReviewModal
				visible={reviewModalVisible}
				onCancel={() => setReviewModalVisible(false)}
				onConfirm={review => {
					if (!review.id) return
					viewModel.setFormData({
						...viewModel.formData,
						landingReviews: (viewModel.formData.landingReviews ?? []).concat(review)
					})
					setReviewModalVisible(false)
				}}
			/>

			<MicrositeTipModal
				visible={tipModalVisible}
				onCancel={() => setTipModalVisible(false)}
				onConfirm={tip => {
					viewModel.setFormData({
						...viewModel.formData,
						tips: (viewModel.formData.tips ?? []).concat(tip)
					})
					setTipModalVisible(false)
				}}
			/>
		</div>
	)
}

export default observer(MicrositesFormView)

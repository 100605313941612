import { observer } from "mobx-react"
import DataTable from "../../../components/DataTable/DataTable"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { useTranslation } from "react-i18next"
import { BillingViewModel } from "../BillingViewModel"
import { AuthStore } from "../../../stores/AuthStore"
import TABLE_COLUMNS_TRANSACTIONS from "../const/TABLE_COLUMNS_TRANSACTIONS"
import dayjs from "dayjs"
import { Checkbox } from "antd"

interface InvoiceTransactionsTableProps {
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const InvoiceTransactionsTable = ({ onChangePage, loading }: InvoiceTransactionsTableProps) => {
	const viewModel = useContainerInjection<BillingViewModel>("BillingViewModel")
	const invoice = viewModel.selectedInvoice
	const handlePageChange = (page: number, pageSize: number, lastPageFetched: number) => {
		viewModel.setTableConfig({ pageSize })
		onChangePage && onChangePage(page, pageSize, lastPageFetched)
	}
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	if (!invoice || !invoice.transactions) return null
	return (
		<DataTable
			loading={loading}
			isFetching={loading}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			onChangePage={handlePageChange}
			columns={TABLE_COLUMNS_TRANSACTIONS(value =>
				viewModel.setSeletectInvoicesTransactionsIds(value ? (invoice.transactions ?? []).map(t => t.id) : [])
			)}
			dataSource={invoice.transactions.map(transaction => {
				return {
					key: transaction.id,
					id: transaction.id,
					user: transaction.userEmail,
					marketplace: transaction.marketplace,
					microsite: transaction.micrositeSlug ?? "-",
					service: transaction.serviceTitle ?? "-",
					session: transaction.serviceSessionTitle ?? "-",
					period: invoice.period,
					checkbox: isAdmin && (
						<Checkbox
							key={transaction.id}
							disabled={viewModel.selectedInvoice?.status !== "pending_emission"}
							checked={viewModel.seletectInvoicesTransactionsIds.includes(transaction.id)}
							onChange={value => {
								viewModel.setSeletectInvoicesTransactionsIds(
									value.target.checked
										? viewModel.selectedInvoiceIds.concat([transaction.id])
										: viewModel.selectedInvoiceIds.filter(id => id !== transaction.id)
								)
							}}
						></Checkbox>
					),
					paymentDate: invoice.paymentDate ? dayjs(invoice.paymentDate).format("DD/MM/YYYY") : "-",
					units: transaction.units,
					sellerPrice: transaction.sellerPrice,
					commission: transaction.sellerFee,
					total: transaction.paymentFinalAmount > 0 ? transaction.paymentFinalAmount : 0
				}
			})}
		/>
	)
}

export default observer(InvoiceTransactionsTable)
